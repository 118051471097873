import { Show, TextField, DateField, useRecordContext, TabbedShowLayout, Tab, SimpleShowLayout } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { FormControlLabel, Grid, InputLabel } from '@mui/material';

const OrderNumber = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{record.order_number} (Rs.{record.price}/=)</span>;
}

const ProductTabs = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        record && record.order_items && record.order_items.map((item)=>{
            return (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <img src={item.product_main_image} title={item.name} style={{maxWidth:'100%'}} />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={8}>
                        <SimpleShowLayout>
                            <strong>Name</strong>
                            <a href={item.product_detail_url}>{item.name}</a>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Price</strong><span>{item.item_price}</span>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Paid Price</strong><span>{item.item_price}</span>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Voucher Seller</strong><span>{item.voucher_seller}</span>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Purchase Price</strong><span>{item.purchase_price}</span><a href={"/#/orderitems/"+item.id}>Edit</a>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Packaging Cost</strong><span>{record.status == 'pending' || record.status == 'unpaid' || record.status == 'ready_to_ship' || record.status == 'shipped' ? 0 : parseFloat(item.packaging_cost)}</span><a href={"/#/orderitems/"+item.id}>Edit</a>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Ad Cost</strong><span>{item.ad_cost}</span><a href={"/#/orderitems/"+item.id}>Edit</a>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Commission Percent</strong><span>{item.commission_percent}</span><a href={"/#/orderitems/"+item.id}>Edit</a>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Payment Fee Percent</strong><span>{item.payment_fee_percent}</span><a href={"/#/orderitems/"+item.id}>Edit</a>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Status</strong><span>{item.status}</span>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Payout Amount</strong><span>{record.status == 'delivered' ? (item.item_price - (record.shipping_fee_discount_seller) - ((record.shipping_fee/record.items_count)*13/100) - (((item.item_price-item.voucher_seller)*item.commission_percent/100)+((item.item_price-item.voucher_seller)*item.commission_percent/100)*13/100) - (((item.item_price-item.voucher_seller)*item.payment_fee_percent/100)+(((item.item_price-item.voucher_seller)*item.payment_fee_percent/100)*13/100)) - item.voucher_seller).toFixed(2) : 0}</span>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleShowLayout>
                            <strong>Profit Amount</strong><span>{record.status == 'delivered' ? (item.item_price - (record.shipping_fee_discount_seller) - ((record.shipping_fee/record.items_count)*13/100) - item.purchase_price - item.packaging_cost - item.ad_cost - (((item.item_price-item.voucher_seller)*item.commission_percent/100)+((item.item_price-item.voucher_seller)*item.commission_percent/100)*13/100) - (((item.item_price-item.voucher_seller)*item.payment_fee_percent/100)+((item.item_price-item.voucher_seller)*item.payment_fee_percent/100)*13/100) - item.voucher_seller).toFixed(2) : (record.status == 'returned' || record.status == 'canceled' || record.status == 'failed' ? -item.packaging_cost-item.ad_cost : 0)}</span>
                        </SimpleShowLayout>
                    </Grid>
                    {
                        (item.status == 'returned' || item.status == 'failed') && <Grid item xs={2}>
                            <SimpleShowLayout>
                                <strong>Return Status By Daraz</strong><span>{item.return_status}</span>
                            </SimpleShowLayout>
                        </Grid>
                    }
                    {
                        (item.status == 'returned' || item.status == 'failed') && <Grid item xs={2}>
                            <SimpleShowLayout>
                                <strong>Return Item Received</strong><span>{item.item_received ? 'Yes' : 'No'}</span>
                            </SimpleShowLayout>
                        </Grid>
                    }
                    {
                        (item.status == 'returned' || item.status == 'failed') && <Grid item xs={2}>
                            <SimpleShowLayout>
                                <strong>Return Item Payment Received</strong><span>{item.item_payment_recieved}</span>
                            </SimpleShowLayout>
                        </Grid>
                    }
                    {
                        item.status == 'canceled' && <Grid item xs={2}>
                            <SimpleShowLayout>
                                <strong>Cancel By</strong><span>{item.cancel_return_initiator}</span>
                            </SimpleShowLayout>
                        </Grid>
                    }
                </Grid>
            )
        })
        
    );
}

const OrderShow = (props) => {
    return (
        <Show title={<OrderNumber/>} {...props}>
            <ProductTabs />
        </Show>
    );
}

export default OrderShow;