import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";
import axios from 'axios';

const AuthProvider = async (type, params,props) => {

    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        
        // simple user username password, redirect funtion
        if (username && password) {

            const response = await axios.post('api/auth/login',{email: username, password})
            .then((resp)=>{
                return resp.data;
            })
            .catch((resp)=>{
                return resp.response.data;
                
            });

            if (response.status){
                localStorage.setItem('token',response.data.token);
                return Promise.resolve();
            }else{
                localStorage.removeItem('token');
                return Promise.reject(response.message);
            }
        }
    }
    if (type === AUTH_LOGOUT) {
        const token = localStorage.getItem('token');
        if ( token ){
            const response = await axios.post('api/auth/logout',{token})
            .then((resp)=>{
                return resp.data;
            })
            .catch((resp)=>{
                return resp.response.data;
            });

            if (response.status){
                localStorage.removeItem('token');
                return Promise.resolve();
            }else{
                return Promise.reject(response.message);
            }
        }
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const { status } = params;
        return status === 401 || status === 403
            ? Promise.reject()
            : Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        if ( localStorage.getItem('token') ){
            return Promise.resolve();
        }else{
            return Promise.reject();
        }
    }
  
    return Promise.reject('Unknown method');
  };

export default AuthProvider;