import { Edit, useRecordContext, SimpleForm, NumberInput, BooleanInput } from 'react-admin';

const ItemTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{record.name}</span>;
}

const OrderItemEdit = (props) => (
    <Edit {...props} title={<ItemTitle/>} redirect="/orders">
        <SimpleForm>
            <NumberInput source="purchase_price" />
            <NumberInput source="packaging_cost" />
            <NumberInput source="ad_cost" />
            <NumberInput source="commission_percent" />
            <NumberInput source="payment_fee_percent" />
            <NumberInput source="handling_fee" />
            <BooleanInput source="item_received" />
            <NumberInput source="item_payment_recieved" />
        </SimpleForm>
    </Edit>
);

export default OrderItemEdit;