import AccountList from './list';
import AccountCreate from './create';
import AccountEdit from './edit';
import AccountShow from './show';

const Accounts = {
    list: AccountList,
    create: AccountCreate,
    edit: AccountEdit,
    show: AccountShow
}

export default Accounts;
