import { List, Datagrid, TextField, DateField, NumberInput, SearchInput, AutocompleteInput, useRecordContext, SelectInput, ReferenceInput, ExportButton, DateInput } from 'react-admin';

const filters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput label="Account" source="account_id" reference="accounts" allowEmpty>
        <AutocompleteInput/>
    </ReferenceInput>,
    <ReferenceInput label="Product" source="name" reference="products" allowEmpty>
        <AutocompleteInput />
    </ReferenceInput>,
    <AutocompleteInput label="Status" source="status" choices={[{id:'unpaid',name:'unpaid'},{id:'pending',name:'pending'},{id:'canceled',name:'canceled'},{id:'ready_to_ship',name:'ready_to_ship'},{id:'delivered',name:'delivered'},{id:'returned',name:'returned'},{id:'shipped',name:'shipped'},{id:'failed',name:'failed'}]} />,
    <DateInput source="order_date" />,
    <AutocompleteInput label="Order Year" source="order_year" choices={[{id:'2020',name:'2020'},{id:'2021',name:'2021'},{id:'2022',name:'2022'},{id:'2023',name:'2023'},{id:'2024',name:'2024'},{id:'2025',name:'2025'},{id:'2026',name:'2026'},{id:'2027',name:'2027'},{id:'2028',name:'2028'},{id:'2029',name:'2029'},{id:'2030',name:'2030'}]} />,
    <AutocompleteInput label="Order Month" source="order_month" choices={[{id:'1',name:'Jan'},{id:'2',name:'Feb'},{id:'3',name:'Mar'},{id:'4',name:'Apr'},{id:'5',name:'May'},{id:'6',name:'Jun'},{id:'7',name:'Jul'},{id:'8',name:'Aug'},{id:'9',name:'Sep'},{id:'10',name:'Oct'},{id:'11',name:'Nov'},{id:'12',name:'Dec'}]} />,
    <ExportButton />
];

const getPayoutAmount = (record,item) => {
    return item.status == 'delivered' ? (item.item_price - (record.shipping_fee_discount_seller) - ((record.shipping_fee/record.items_count)*13/100) - (((item.item_price-item.voucher_seller)*item.commission_percent/100)+((item.item_price-item.voucher_seller)*item.commission_percent/100)*13/100) - (((item.item_price-item.voucher_seller)*item.payment_fee_percent/100)+((item.item_price-item.voucher_seller)*item.payment_fee_percent/100)*13/100) - item.voucher_seller).toFixed(2) : ( item.status == 'returned' || item.status == 'failed' ? item.item_payment_recieved : 0 );
}

const PurchasePrice = () => {
    const record = useRecordContext();

    if (!record) return null;
    let pp = 0;
    return record && record.order_items && record.order_items.map((item,i)=>{
        pp += parseFloat(item.purchase_price);
        return <span key={item.id}>
            {i > 0 ? ' + ' : ''}
            <a href={"/#/orderitems/"+item.id} title={item.name}>
                {item.purchase_price}
            </a>
            {record.order_items.length > 1 && record.order_items.length - 1 == i ? ' = '+pp.toFixed(2) : ''}
            </span>
    });
}

const PackagingCost = () => {
    const record = useRecordContext();

    if (!record) return null;
    let pp = 0;
    return record && record.order_items && record.order_items.map((item,i)=>{
        const p = record.status == 'pending' || record.status == 'unpaid' || record.status == 'ready_to_ship' || record.status == 'shipped' ? 0 : parseFloat(item.packaging_cost);
        pp += p;
        return <span key={item.id}>
            {i > 0 ? ' + ' : ''}
            <a href={"/#/orderitems/"+item.id} title={item.name}>
                {p}
            </a>
            {record.order_items.length > 1 && record.order_items.length - 1 == i ? ' = '+pp.toFixed(2) : ''}
            </span>
    });
}

const AdCost = () => {
    const record = useRecordContext();

    if (!record) return null;
    let pp = 0;
    return record && record.order_items && record.order_items.map((item,i)=>{
        pp += parseFloat(item.ad_cost);
        return <span key={item.id}>
            {i > 0 ? ' + ' : ''}
            <a href={"/#/orderitems/"+item.id} title={item.name}>
                {item.ad_cost}
            </a>
            {record.order_items.length > 1 && record.order_items.length - 1 == i ? ' = '+pp.toFixed(2) : ''}
            </span>
    });
}

const PayoutAmount = () => {
    const record = useRecordContext();

    if (!record) return null;
    let pp = 0;
    return record && record.order_items && record.order_items.map((item,i)=>{
        const p = getPayoutAmount(record,item);
        pp += parseFloat(p);
        return <span key={item.id}>
            {i > 0 ? ' + ' : ''}
            {p}
            {record.order_items.length > 1 && record.order_items.length - 1 == i ? ' = '+pp.toFixed(2) : ''}
            </span>
    });
}

const ProfitAmount = () => {
    const record = useRecordContext();

    if (!record) return null;
    let pp = 0;
    return record && record.order_items && record.order_items.map((item,i)=>{
        const p = item.status == 'delivered' ? (getPayoutAmount(record,item) - item.voucher_seller - item.purchase_price - item.packaging_cost - item.ad_cost).toFixed(2) : (record.status == 'returned' || record.status == 'canceled' || record.status == 'failed' ? getPayoutAmount(record,item)-item.packaging_cost-item.ad_cost : 0);
        pp += parseFloat(p);
        return <span key={item.id}>
            {i > 0 ? ' + ' : ''}
            {p}
            {record.order_items.length > 1 && record.order_items.length - 1 == i ? ' = '+pp.toFixed(2) : ''}
            </span>
    });
}

const ItemReceived = () => {
    const record = useRecordContext();

    if (!record) return null;
    let pp = 0;
    return record && record.order_items && record.order_items.map((item,i)=>{
        const r = item.item_received ? 'Yes' : 'No';
        const p = item.item_payment_recieved || 0;
        pp += parseFloat(p);
        if ( r == 'Yes' ){
            if ( p > 0 ){
                return <span key={item.id}>
                    {i > 0 ? ' + ' : ''}
                    {p}
                    {record.order_items.length > 1 && record.order_items.length - 1 == i ? ' = '+pp.toFixed(2) : ''}
                </span>
            }else{
                return <span key={item.id}>
                    {i > 0 ? ' + ' : ''}
                    Yes
                </span>
            }
        }else{
            return <span key={item.id}>
            {i > 0 ? ' + ' : ''}
            No
            </span>
        }
    });
}

const postRowStyle = (record, index) => ({
    backgroundColor: record.status == 'delivered' ? '#a5e2a5' : (record.status == 'canceled' || record.status == 'returned' || record.status == 'failed' ? '#e2a5a5' : (record.status == 'ready_to_ship' || record.status == 'shipped' ? '#e2d2a5' : '#cccccc')),
});

const OrderList = () => (
    <List filters={filters}>
        <Datagrid rowClick='show' rowStyle={postRowStyle}>
            <TextField source="order_number" />
            <TextField source="items_count" label="No. of Items" />
            <TextField source="price" />
            <PurchasePrice label="Purchase Price" />
            <PackagingCost label="Packaging Cost" />
            <AdCost label="Ad Cost" />
            <PayoutAmount label="Payout Amount" />
            <ProfitAmount label="Profit Amount" />
            <TextField source="status" />
            <TextField source="account_name" />
            <ItemReceived label="Return Item Received" />
            <DateField source="order_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
        </Datagrid>
    </List>
);

export default OrderList;