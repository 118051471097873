import { Edit, SimpleForm, TextInput } from 'react-admin';

const AccountEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list">
            <TextInput source="id" disabled />
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export default AccountEdit;