import './App.css';
import { Admin, Resource } from 'react-admin';
import DataProvider from './DataProvider';
import Accounts from './accounts';
import Orders from './orders';
import OrderItems from './order-items';
import Products from './products';
import AuthProvider from './AuthProvider';

const dataProvider = DataProvider('api/admin');

function App() {
  return (
    <div className="App">
      <Admin authProvider={AuthProvider} dataProvider={dataProvider}>
        <Resource name="accounts" {...Accounts} />
        <Resource name="orders" {...Orders} />
        <Resource name="orderitems" {...OrderItems} />
        <Resource name="products" {...Products} />
      </Admin>
    </div>
  );
}

export default App;
