import { Show, TextField, DateField, useRecordContext, TabbedShowLayout, Tab, SimpleShowLayout } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { FormControlLabel, Grid, InputLabel } from '@mui/material';

const useStyles = makeStyles(theme => ({
    tabbedCustom: {
        '& .RaTabbedShowLayout-content': {
            padding: '12px 0',
        }
    }
  }));

const AccountName = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{record.name}</span>;
}

const ReportTabs = () => {
    
    const classes = useStyles();
    
    const record = useRecordContext();
    if (!record) return null;

    return (
        <TabbedShowLayout className={classes.tabbedCustom}>
        {
            record && record.reports && record.reports.map((report)=>{
                
                return (
                    <Tab label={report.year+'-Statement'}>
                        <Grid container spacing={2} style={{background: '#cccccc'}}>
                            <Grid item xs={4}>
                                <SimpleShowLayout>
                                    <label>Totals</label>
                                    <span>{report.year}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={2}>
                                <SimpleShowLayout>
                                    <label>Revenue</label>
                                    <span>{report.totalRevenue}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={2}>
                                <SimpleShowLayout>
                                    <label>Purchases</label>
                                    <span>{report.totalPurchases}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={2}>
                                <SimpleShowLayout>
                                    <label>Expenses</label>
                                    <span>{report.totalExpenses}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={2}>
                                <SimpleShowLayout>
                                    <label>Profit</label>
                                    <span>{report.totalProfit}</span>
                                </SimpleShowLayout>
                            </Grid>
                        </Grid>
                        {
                            report.items && report.items.map((item)=>{
                                return (
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <SimpleShowLayout>
                                                <label>Year/Month</label>
                                                <span>{item.year}/{item.month}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <SimpleShowLayout>
                                                <label>Revenue</label>
                                                <span>{item.revenue}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <SimpleShowLayout>
                                                <label>Purchases</label>
                                                <span>{item.purchases}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <SimpleShowLayout>
                                                <label>Expenses</label>
                                                <span>{item.expenses}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <SimpleShowLayout>
                                                <label>Profit</label>
                                                <span>{item.profit}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                    </Grid>
                                )       
                            })
                        }
                    </Tab>
                )
            })
        }
        {
            record && record.reportsByStatus && record.reportsByStatus.map((report)=>{
                return (
                    <Tab label={report.year+'-Count'}>
                        <Grid container spacing={2} style={{background: '#cccccc'}}>
                            <Grid item xs={1.5}>
                                <SimpleShowLayout>
                                    <label>Totals</label>
                                    <span>{report.year}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={1.5}>
                                <SimpleShowLayout>
                                    <label>Canceled</label>
                                    <span>{report.totalCanceledOrders} | {report.totalCanceledOrderItems}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={1.5}>
                                <SimpleShowLayout>
                                    <label>Pending</label>
                                    <span>{report.totalPendingOrders} | {report.totalPendingOrderItems}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={1.5}>
                                <SimpleShowLayout>
                                    <label>Ready to Ship</label>
                                    <span>{report.totalReadytoshipOrders} | {report.totalReadytoshipOrderItems}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={1.5}>
                                <SimpleShowLayout>
                                    <label>Shipped</label>
                                    <span>{report.totalShippedOrders} | {report.totalShippedOrderItems}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={1.5}>
                                <SimpleShowLayout>
                                    <label>Delivered</label>
                                    <span>{report.totalDeliveredOrders} | {report.totalDeliveredOrderItems}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={1.5}>
                                <SimpleShowLayout>
                                    <label>Returned</label>
                                    <span>{report.totalReturnedOrders} | {report.totalReturnedOrderItems}</span>
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item xs={1.5}>
                                <SimpleShowLayout>
                                    <label>Failed</label>
                                    <span>{report.totalFailedOrders} | {report.totalFailedOrderItems}</span>
                                </SimpleShowLayout>
                            </Grid>
                        </Grid>
                        {
                            report.items && report.items.map((item)=>{
                                return (
                                    <Grid container spacing={2}>
                                        <Grid item xs={1.5}>
                                            <SimpleShowLayout>
                                                <label>Year/Month</label>
                                                <span>{item.year}/{item.month}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <SimpleShowLayout>
                                                <label>Canceled</label>
                                                <span>{item.canceled_orders} | {item.canceled_items}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <SimpleShowLayout>
                                                <label>Pending</label>
                                                <span>{item.pending_orders} | {item.pending_items}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <SimpleShowLayout>
                                                <label>Ready To Ship</label>
                                                <span>{item.ready_to_ship_orders} | {item.ready_to_ship_items}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <SimpleShowLayout>
                                                <label>Shipped</label>
                                                <span>{item.shipped_orders} | {item.shipped_items}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <SimpleShowLayout>
                                                <label>Delivered</label>
                                                <span>{item.delivered_orders} | {item.delivered_items}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <SimpleShowLayout>
                                                <label>Returned</label>
                                                <span>{item.returned_orders} | {item.returned_items}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <SimpleShowLayout>
                                                <label>Failed</label>
                                                <span>{item.failed_orders} | {item.failed_items}</span>
                                            </SimpleShowLayout>
                                        </Grid>
                                    </Grid>
                                )       
                            })
                        }
                    </Tab>
                )
            })
        }
        </TabbedShowLayout>
        
    );
}

const AccountShow = (props) => {
    return (
        <Show title={<AccountName/>} {...props}>
            <ReportTabs />
        </Show>
    );
}

export default AccountShow;